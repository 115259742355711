<template>
    <div>
        <CRow>
            <CCol sm="12">
            <button
                type="button"
                v-c-tooltip.hover="{
                    content: `Contrato`,
                }"
                :class="btnClasses"
                :style="vue.estado"
                @click="actionButton"
            >
                <i :class="vue.icon"></i> {{ vue.texto }}
            </button>
            </CCol>
            <CCol sm="12">&nbsp;</CCol>
        </CRow>

        <!-- modal Up Contract -->
        <CModalForm
            :size="'md'"
            title="Subir Contrato"
            :button="cp_button"
            :show.sync="modal.modal_form"
            @mtd_action="mtd_action"
        >
            <CRow>
                <CCol col="12">
                    <input
                        type="file" 
                        name="archivo" 
                        @change="getArchivo"
                        label="Subir Archivo"
                        placeholder="Subir Archivo"
                    />
                </CCol>
            </CRow>
        </CModalForm>
    </div>
</template>

<script>
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
export default({
    name: "file",
    components: { CModalForm },
    data(){
        return{
            prefix: "sale",
            vue:{
                texto: "",
                estado: 'display:none',
                icon: '',
            },
            contract:{
                id: null,
                contract: null,
                uuid: null,
            },
            modal: {
                action: "",
                title: "",
                modal_form: false,
                item: [],
            },
            archivo : null,
        }
    },
    props:{
        sale_id: Number
    },
    computed:{
        btnClasses() {
            return [`btn btn-${this.color || "primary"} float-left`];
        },
        cp_button: function () {
            if (this.archivo != null ) return false;
                return true;
        }, 
    },
    created(){
        this.mtd_verificarfile(this.sale_id);
    },
    methods:{
        ...mapActions(["get", "post"]),
        mtd_verificarfile: function(sale_id){

            this.post({
                url: this.$store.getters.get__url + "/" + this.prefix + "/show",
                token: this.$store.getters.get__token,
                params:{
                    id: sale_id,
                }
            }).then((response)=>{
                let contrato, file;

                contrato= response.data.contract;
                file = response.data.uuid;

                this.ShowHide(contrato);

                if(contrato==1 && file==null || file==''){
                    this.vue.texto = "Subir Contrato";
                    this.vue.icon = "fas fa-upload";
                }else{
                    this.vue.texto = "Ver Contrato";
                    this.vue.icon = "fas fa-eye";
                }

                this.contract = response.data;
            }).catch((errors)=>{});
        },
        //Action Button
        actionButton: function(){
            let contract, file;

            contract= this.contract.contract;
            file = this.contract.uuid;

            if(contract==1 && file==null || file==''){
                // Load Modal
                this.modal.modal_form = true;
            }else{
                // View File
                this.mtd_loadfile(this.contract.id);
            }
        },
        getArchivo(event){
            this.archivo = event.target.files[0];
        },
        // Save File
        mtd_action: function(){
            var data = new  FormData();
            data.append('contrato', this.archivo);
            data.append('sale_id', this.contract.id);

            this.post({
                url: this.$store.getters.get__url+"/"+this.prefix+"/uploadFile",
                token: this.$store.getters.get__token,
                params: data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response)=>{
                if(response.length>0){
                    this.modal.modal_form = false;
                    this.mtd_verificarfile(this.contract.id);
                }
            }).catch((errors)=>{});
        },
        // Load File
        mtd_loadfile: function(sale_id){
            this.post({
                url: this.$store.getters.get__url + "/" + this.prefix + "/show",
                token: this.$store.getters.get__token,
                params:{
                    id: sale_id,
                }
            }).then((response)=>{
                window.open("/assets/pdf/"+response.data.uuid, '_blank');
            }).catch((errors)=>{});
        },
        // Show/Hide Button
        ShowHide: function(status){
            return status == 2
            ? this.vue.estado="display:none"
            : this.vue.estado="display:block";
        },
    }
})
</script>
