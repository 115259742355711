<template>
  <CRow>
    <CCol xs="12" lg="12">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol xs="4" lg="4">
              <CInput
                label="N° de dni"
                placeholder="Digite número de dni"
                v-model="dni"
                @keypress.enter="mtd_getpurchase"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" xs="12" lg="6">
              <CInput
                label="Cliente"
                horizontal
                autocomplete="off"
                v-model="client.name"
                readonly
              />
            </CCol>
            <CCol sm="12" xs="12" lg="6">
              <CInput
                label="Dni"
                horizontal
                autocomplete="off"
                v-model="client.dni"
                readonly
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12" sm="12" lg="6">
              <CInput
                label="Correo"
                horizontal
                autocomplete="off"
                v-model="client.email"
                readonly
              />
            </CCol>
            <CCol xs="12" lg="6" sm="12">
              <CInput
                label="Dirección"
                horizontal
                autocomplete="off"
                v-model="client.address"
                readonly
              />
            </CCol>
          </CRow>
          <br>
          <CRow>
            <CCol xs="12" sm="12" lg="12">
                <CTabs :active-tab.sync="activeTab">
                    <CTab v-for="(item, index) in list" :key="index"  >
                        <template slot="title">
                            <CIcon name="cil-calculator" /> {{item.name}}
                        </template>
                        <br>
                        <cDetail :detail="mtd_getdetail(item.id)"></cDetail>
                    </CTab>
                </CTabs>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import cDetail from '../../components/charge/detail.vue'
import { mapActions } from "vuex";
import { bus } from "../../main";
export default {
  name: "v-charge-index",
  components:{cDetail},
  data() {
    return {
      dni: null,
      client: {
        name: null,
        dni: null,
        address: null,
        email: null,
      },
      tabs: [
        'Por cotización',
        'Venta directa',
      ],
      activeTab: 0,
      list:[],
      detail:[],
      lots:[],
    };
  },
  methods: {
      ...mapActions(["get", "post"]),
    mtd_getpurchase: function () {
      if (this.dni != null && this.dni != "") {
        this.get({
          url:
            this.$store.getters.get__url +
            "/charge/" +
            this.dni +
            "/show",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if(response.state == 0){
                this.client.name = response.client.last_name+ " "+ response.client.name;
                this.client.dni = response.client.dni=response.client.dni;
                this.client.address = response.client.address;
                this.client.email = response.client.email;
                this.list = response.data;
                this.detail = response.detail;
                bus.$emit("alert", {
                    color: "success",
                    message: response.message,
                });
            }else{
                bus.$emit("alert", {
                    color: "danger",
                    message: response.message,
                });
            }
          })
          .catch((errors) => {});
      } else {
        bus.$emit("alert", {
          color: "danger",
          message: "Digite un número de cotización",
        });
      }
    },
    mtd_getdetail: function(project){
      let response = [];
      this.detail.forEach(element => {
        if (element.project_id == project) {
          response.push(element);
        }
      });
      // console.log(response);
      return response;
    }
  },
  
};
</script>

<style lang="scss" scoped>
</style>