var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('button',{staticClass:"btn btn-md btn-info badge",style:(_vm.vue.estado),attrs:{"type":"button"},on:{"click":_vm.actionButton}},[_c('strong',[_vm._v(" "+_vm._s(_vm.vue.texto))])])]),_c('CCol',{attrs:{"sm":"12"}},[_vm._v(" ")])],1),_c('CModalForm',{attrs:{"title":'Pagar cuota S/' + _vm.payment.amount,"button":_vm.cp_button,"show":_vm.modal.modal_form,"size":'lg'},on:{"update:show":function($event){return _vm.$set(_vm.modal, "modal_form", $event)},"mtd_action":_vm.mtd_action}},[(_vm.page == 1)?_c('CRow',[_c('CCol',{staticClass:"text-center",attrs:{"sm":"12"}},[_c('h5',[_vm._v("MÉTODO DE PAGO")])]),_c('CCol',{attrs:{"sm":"4"}},[_c('div',{class:_vm.form_add_tipo_pago.id == 1
              ? 'text-white bg-gradient-success'
              : 'card',on:{"click":function($event){return _vm.mtd_select_type(1)}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"h1 text-muted text-right mb-4 text-center"},[_c('i',{staticClass:"fas fa-money-bill-alt"})]),_c('div',{staticClass:"h4 mb-0"},[_vm._v("EFECTIVO")])])])]),_c('CCol',{attrs:{"sm":"4"}},[_c('div',{class:_vm.form_add_tipo_pago.id == 2
              ? 'text-white bg-gradient-info'
              : 'card',on:{"click":function($event){return _vm.mtd_select_type(2)}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"h1 text-muted text-right mb-4 text-center"},[_c('i',{staticClass:"fas fa-credit-card"})]),_c('div',{staticClass:"h4 mb-0"},[_vm._v("TARJETA")])])])]),_c('CCol',{attrs:{"sm":"4"}},[_c('div',{class:_vm.form_add_tipo_pago.id == 3
              ? 'text-white bg-gradient-warning'
              : 'card',on:{"click":function($event){return _vm.mtd_select_type(3)}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"h1 text-muted text-right mb-4 text-center"},[_c('i',{staticClass:"fas fa-exchange-alt"})]),_c('div',{staticClass:"h4 mb-0"},[_vm._v("TRANSFERENCIA")])])])]),_c('CCol',{staticClass:"text-center",attrs:{"sm":"12"}},[_c('h5',[_vm._v("TIPO DE DOCUMENTO")])]),_c('CCol',{attrs:{"sm":"3"}}),_c('CCol',{attrs:{"sm":"3"}},[_c('div',{class:_vm.form_add_tipo_doc.id == 1
              ? 'text-white bg-gradient-primary'
              : 'card',on:{"click":function($event){return _vm.mtd_select_document(1)}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"h1 text-muted text-right mb-4 text-center"},[_c('i',{staticClass:"fas fa-file-invoice-dollar"})]),_c('div',{staticClass:"h4 mb-0"},[_vm._v("BOLETA")])])])]),_c('CCol',{attrs:{"sm":"3"}},[_c('div',{class:_vm.form_add_tipo_doc.id == 2
              ? 'text-white bg-gradient-primary'
              : 'card',on:{"click":function($event){return _vm.mtd_select_document(2)}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"h1 text-muted text-right mb-4 text-center"},[_c('i',{staticClass:"fas fa-file-invoice-dollar"})]),_c('div',{staticClass:"h4 mb-0"},[_vm._v("FACTURA")])])])]),_c('CCol',{attrs:{"sm":"3"}}),_c('CCol',{staticClass:"text-right",attrs:{"sm":"12"}},[_c('h6',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.mtd_change_page(2)}}},[_vm._v("CONTINUAR")])])],1):_c('CRow',[_c('CCol',{attrs:{"sm":"2"}}),_c('CCol',{attrs:{"sm":"4"}},[_c('CInput',{attrs:{"label":"Fecha de pago","type":"date"},model:{value:(_vm.payment.date),callback:function ($$v) {_vm.$set(_vm.payment, "date", $$v)},expression:"payment.date"}})],1),_c('CCol',{attrs:{"sm":"4"}},[_c('CInput',{attrs:{"label":"Número de documento","placeholder":"B00-0000000"},model:{value:(_vm.payment.number_document),callback:function ($$v) {_vm.$set(_vm.payment, "number_document", $$v)},expression:"payment.number_document"}})],1),_c('CCol',{attrs:{"sm":"2"}}),_c('CCol',{attrs:{"sm":"2"}}),(this.form_add_tipo_pago.id != 1)?_c('CCol',{attrs:{"sm":"4"}},[_c('cSelectForm',{attrs:{"label":"Seleccione nombre banco","options":_vm.data_banco,"placeholder":"Escoja un banco","value":_vm.payment.bank_id},on:{"update:value":function($event){return _vm.$set(_vm.payment, "bank_id", $event)}}})],1):_vm._e(),(this.form_add_tipo_pago.id != 1)?_c('CCol',{attrs:{"sm":"4"}},[_c('CInput',{attrs:{"placeholder":"00000","label":"N° voucher"},model:{value:(_vm.payment.number),callback:function ($$v) {_vm.$set(_vm.payment, "number", $$v)},expression:"payment.number"}})],1):_vm._e(),_c('CCol',{staticClass:"text-right",attrs:{"sm":"12"}},[_c('h6',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.mtd_change_page(1)}}},[_vm._v("REGRESAR")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }