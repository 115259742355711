<template>
  <div>
    <CRow>
      <CCol sm="12">
        <button
          type="button"
          class="btn btn-md btn-info badge"
          @click="actionButton"
          :style="vue.estado"
        >
          <strong> {{ vue.texto }}</strong>
          <!-- <i :class="vue.icon"></i> {{ vue.texto }} -->
        </button>
      </CCol>
      <CCol sm="12">&nbsp;</CCol>
    </CRow>
    <!-- MODAL PAGO -->
    <CModalForm
      :title="'Pagar cuota S/' + payment.amount"
      :button="cp_button"
      :show.sync="modal.modal_form"
      :size="'lg'"
      @mtd_action="mtd_action"
    >
      <CRow v-if="page == 1">
        <CCol sm="12" class="text-center">
          <h5>MÉTODO DE PAGO</h5>
        </CCol>
        <CCol sm="4">
          <div
            @click="mtd_select_type(1)"
            :class="
              form_add_tipo_pago.id == 1
                ? 'text-white bg-gradient-success'
                : 'card'
            "
          >
            <div class="card-body">
              <div class="h1 text-muted text-right mb-4 text-center">
                <i class="fas fa-money-bill-alt"></i>
              </div>
              <div class="h4 mb-0">EFECTIVO</div>
            </div>
          </div>
        </CCol>
        <CCol sm="4">
          <div
            @click="mtd_select_type(2)"
            :class="
              form_add_tipo_pago.id == 2
                ? 'text-white bg-gradient-info'
                : 'card'
            "
          >
            <div class="card-body">
              <div class="h1 text-muted text-right mb-4 text-center">
                <i class="fas fa-credit-card"></i>
              </div>
              <div class="h4 mb-0">TARJETA</div>
            </div>
          </div>
        </CCol>
        <CCol sm="4">
          <div
            @click="mtd_select_type(3)"
            :class="
              form_add_tipo_pago.id == 3
                ? 'text-white bg-gradient-warning'
                : 'card'
            "
          >
            <div class="card-body">
              <div class="h1 text-muted text-right mb-4 text-center">
                <i class="fas fa-exchange-alt"></i>
              </div>
              <div class="h4 mb-0">TRANSFERENCIA</div>
            </div>
          </div>
        </CCol>
        <CCol sm="12" class="text-center">
          <h5>TIPO DE DOCUMENTO</h5>
        </CCol>
        <CCol sm="3"></CCol>
        <CCol sm="3">
          <div
            @click="mtd_select_document(1)"
            :class="
              form_add_tipo_doc.id == 1
                ? 'text-white bg-gradient-primary'
                : 'card'
            "
          >
            <div class="card-body">
              <div class="h1 text-muted text-right mb-4 text-center">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="h4 mb-0">BOLETA</div>
            </div>
          </div>
        </CCol>
        <CCol sm="3">
          <div
            @click="mtd_select_document(2)"
            :class="
              form_add_tipo_doc.id == 2
                ? 'text-white bg-gradient-primary'
                : 'card'
            "
          >
            <div class="card-body">
              <div class="h1 text-muted text-right mb-4 text-center">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="h4 mb-0">FACTURA</div>
            </div>
          </div>
        </CCol>
        <CCol sm="3"></CCol>
        <CCol sm="12" class="text-right">
          <h6 @click="mtd_change_page(2)" style="cursor:pointer">CONTINUAR</h6>
        </CCol>
      </CRow>
      <CRow v-else>
        <CCol sm="2"></CCol>
        <CCol sm="4">
          <CInput label="Fecha de pago" type="date" v-model="payment.date" />
        </CCol>
        <CCol sm="4">
            <CInput
            label="Número de documento"
            placeholder="B00-0000000"
            v-model="payment.number_document"          
            />
        </CCol>
        <CCol sm="2"></CCol>
        <CCol sm="2"></CCol>
        <CCol sm="4" v-if="this.form_add_tipo_pago.id != 1">
          <cSelectForm
            label="Seleccione nombre banco"
            :options="data_banco"
            placeholder="Escoja un banco"
            :value.sync="payment.bank_id"
          />
        </CCol>
        
        <CCol sm="4" v-if="this.form_add_tipo_pago.id != 1">
          <CInput placeholder="00000" label="N° voucher" v-model="payment.number" />
        </CCol>
        <CCol sm="12" class="text-right">
          <h6 @click="mtd_change_page(1)" style="cursor:pointer">REGRESAR</h6>
        </CCol>
      </CRow>
    </CModalForm>
  </div>
</template>
<script>
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
export default {
  name: "payment",
  components: { CModalForm, cSelectForm },
  props: {
    detail_id: Number,
  },
  data() {
    return {
      vue: {
        texto: "PAGAR",
        estado: "",
        icon: "fas fa-file-invoice-dollar",
      },
      page: 1,
      modal: {
        action: "",
        title: "",
        modal_form: false,
        item: [],
      },
      payment: {
        detail_fee_id: "",
        bank_id: null,
        amount: null,
        number: null,
        date: null,
        number_document: null,
      },
      form_detail: {
        id: "",
        state: "",
      },
      data_banco: [],
      data_tipoPago: [],
      form_add_tipo_pago: {
        id: "",
        state: "",
      },
      data_tipoDocument: [],
      form_add_tipo_doc: {
        id: "",
        state: "",
      },
    };
  },
  computed: {
    cp_button: function () {
        if (this.form_add_tipo_pago.id != "" && this.form_add_tipo_doc.id != "") {
            if(this.form_add_tipo_pago.id == 1){
                if (this.payment.date != null && this.payment.number_document) {
                    return false;
                } else {
                    return true;
                }
            }else{
                if (this.payment.date != null && this.payment.number_document && this.payment.bank_id != null && this.payment.number != null) {
                    return false;
                } else {
                    return true;
                }
            }            
        } else {
            return true;
        }
    },
  },
  created() {
    this.form_add_tipo_pago.id = null;
    this.form_add_tipo_doc.id = null;
    this.page = 1;
    let actual = new Date();
      let fecha;
      fecha =
        actual.getFullYear() +
        "-" +
        ("0" + (actual.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + actual.getDate()).slice(-2);
      this.payment.date = fecha;
    this.mtd_getdata_bancos();
    this.mtd_getdata_detailsfeeds();
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    ...mapActions(["get", "post"]),
    mtd_select_type: function (val) {
      this.form_add_tipo_pago.id = val;
    },
    mtd_select_document: function (val) {
      this.form_add_tipo_doc.id = val;
    },
    mtd_change_page: function (page) {
      if (page == 2) {
        if (
          this.form_add_tipo_doc.id != null &&
          this.form_add_tipo_pago.id != null
        ) {
          this.page = page;
        } else {
          bus.$emit("alert", {
            color: "danger",
            message: "Seleccione un método de pago y un tipo de documento",
          });
        }
      } else {
        this.page = page;
      }
    },
    //Action Button
    actionButton: function () {
      this.modal.modal_form = true;
    },
    mtd_getdata_bancos: function () {
      this.get({
        url: this.$store.getters.get__url + "/charge/bank",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_banco = response.data_banco;
        })
        .catch((errors) => {});
    },
    mtd_getdata_detailsfeeds: function () {
      let detail_id;
      detail_id = this.detail_id;
      this.get({
        url:
          this.$store.getters.get__url + "/charge/" + detail_id + "/showdetail",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.payment.amount = response.amortization;
          this.payment.detail_fee_id = response.id;
        })
        .catch((errors) => {});
    },
    mtd_action: function () {
      let elementos;
      elementos = {
        detail_fee_id: this.payment.detail_fee_id,
        type_payment: this.form_add_tipo_pago.id,
        date: this.payment.date,
        bank_id: this.payment.bank_id,
        number_transaction: this.payment.number,
        amount: this.payment.amount,
        type_document: this.form_add_tipo_doc.id,
        number_document: this.payment.number_document,
        user_id: this.$store.getters.get__user_id,
      };
      this.post({
        url: this.$store.getters.get__url + "/charge/payment",
        token: this.$store.getters.get__token,
        params: elementos,
      })
        .then((response) => {
            this.$emit('commit_charge',this.payment.detail_fee_id);
          this.payment = {
            bank_id: "",
            amount: "",
            number: "",
            date: "",
          };
          this.form_add_tipo_pago = {
            id: "",
            state: "",
          };
          this.form_add_tipo_doc = {
            id: "",
            state: "",
          };
          this.form_detail = {
            id: "",
            state: "",
          };
          bus.$emit("alert", {
            color: "success",
            message: "Pago registrado",
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
      this.modal.modal_form = false;
    },
  },
};
</script>
