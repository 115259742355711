<template>
  <CRow>
    <CCol col="4">
      <CCard>
        <CCardBody>
          <CListGroup accent>
            <CListGroupItem
              v-for="(det, i) in detail"
              :key="i"
              href="javascript:void(0)"
              :color="active == det.id ? 'success' : 'light'"
              :accent="active == det.id ? 'success' : 'light'"
              @click="mtd_selectsale(det.id)"
            >
              ETAPA: <strong>{{ det.name_street }}</strong> LOTE:
              <strong>{{ det.name_lot }}</strong></CListGroupItem
            >
          </CListGroup>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol col="8">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-justify-center" />
          <strong> Detalle de pagos </strong>
          <small></small>
        </CCardHeader>
        <CCardBody>
          <div v-if="active != 0">
            <div v-for="(det, i) in detail" :key="i">
              <cFile :sale_id="det.id"></cFile>
            </div>
          </div>
          <table
            class="table table-striped table-fixed table-hover table-bordered"
          >
            <thead class="table-succcess">
              <tr>
                <th>
                  <div>N° cuota</div>
                </th>
                <th>
                  <div>Dueda</div>
                </th>
                <th>
                  <div>Amortización</div>
                </th>
                <th>
                  <div>Pendietne</div>
                </th>
                <th>
                  <div>Fecha de vencimiento</div>
                </th>
                <th>
                  <div>Acción</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="active != 0">
              <tr
                v-for="(item, index) in details"
                :key="index"
                :class="item.class"
              >
                <td class="">
                  {{ item.number == 0 ? "INICIAL" : "CUOTA " + item.number }}
                </td>
                <td class="">
                  S/{{ parseFloat(item.pending_after).toFixed(2) }}
                </td>
                <td class="">
                  S/{{ parseFloat(item.amortization).toFixed(2) }}
                </td>
                <td class="">
                  S/{{ parseFloat(item.pending_before).toFixed(2) }}
                </td>
                <td class="">{{ item.date }}</td>
                <td class="text-center">
                   <cPayment v-if="item.botton == true && item.state == 0" :detail_id="item.id" @commit_charge="commit_charge"></cPayment>
                   <cPayment v-if="item.botton == false && item.state == 2" :detail_id="item.id" @commit_charge="commit_charge"></cPayment>
                  <span v-if="item.botton == false && item.state==1" style="height: 23px; width: 90%;font-size: 15px;" class="badge badge-success">PAGADO</span>
                  
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="table-danger">
                <td colspan="6" class="text-center">ESCOJA UN LOTE</td>
              </tr>
            </tbody>
          </table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import cFile from './file.vue';
import cPayment from './payment.vue';
import { bus } from "../../main";
import { mapActions } from "vuex";
export default {
  components: { cFile, cPayment },
  props: {
    detail: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      active: 0,
      details: [],
    };
  },
  created() {},
  methods: {
    ...mapActions(["get", "post"]),
    mtd_selectsale: function (det_id) {
      if (det_id != this.active) {
        this.get({
          url:
            this.$store.getters.get__url + "/charge/" + det_id + "/getcuotas",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.details = response;
            this.active = det_id;
          })
          .catch((errors) => {
            bus.$emit("alert", {
              color: "danger",
              message: errors,
            });
          });
      }
    },
    commit_charge:function(id){
      let pos = null;
      this.details.forEach((element,index) => {
        if (element.id == id) {
          pos = index+1;
          element.botton =false;
          element.state=1;
          element='table-success';
        }
        if (index == pos) {
          element.botton =true;
          element.state=0;
        }
      });
      
    }
  },
};
</script>

<style lang="scss" scoped>
</style>